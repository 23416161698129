import * as React from "react"

export const ShieldIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43.284}
    height={49.975}
    {...props}
  >
    <path
      data-name="Zeichenfla\u0308che 60"
      d="m43.284 25.603-3.426 3.408a18.259 18.259 0 0 1-36.5-.678V12.739l18.288-9.135.714.375 16.343 8.189-.446.428-16.615 16.647-6.637-6.655-2.409 2.409 7.85 7.868a1.41 1.41 0 0 0 .553.357 1.606 1.606 0 0 0 1.285 0 1.409 1.409 0 0 0 .553-.357L42.784 12.9a1.784 1.784 0 0 0 .41-.7 1.463 1.463 0 0 0 0-.785 1.624 1.624 0 0 0-.3-.749 1.784 1.784 0 0 0-.624-.5L22.356.179a1.606 1.606 0 0 0-.749-.178 1.624 1.624 0 0 0-.767.178L.91 10.15a1.552 1.552 0 0 0-.642.624 1.784 1.784 0 0 0-.268.91v16.649a21.642 21.642 0 0 0 43.284 0Z"
      fill="#222b45"
    />
  </svg>
)
