import * as React from "react"

export const SettingsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={62.464}
    height={48.797}
    {...props}
  >
    <g data-name="Zeichenfla\u0308che 54" fill="#222b45">
      <path
        data-name="Pfad 802"
        d="M60.679 22.605H23.693a1.784 1.784 0 1 0 0 3.568h36.986a1.784 1.784 0 1 0 0-3.568Z"
      />
      <path
        data-name="Pfad 803"
        d="M14.273 40.715H1.784a1.784 1.784 0 1 0 0 3.568h12.489a1.784 1.784 0 1 0 0-3.568Z"
      />
      <path
        data-name="Pfad 804"
        d="M60.679 4.496h-5.66a1.785 1.785 0 1 0 0 3.57h5.66a1.784 1.784 0 1 0 0-3.568Z"
      />
      <path
        data-name="Pfad 805"
        d="M12.007 30.759h2.266a1.784 1.784 0 0 0 1.784-1.784v-9.082a1.784 1.784 0 0 0-1.784-1.784h-2.266a1.784 1.784 0 0 0-1.784 1.784v2.712H1.837a1.784 1.784 0 1 0 0 3.568h8.368v2.712a1.784 1.784 0 0 0 1.8 1.873Z"
      />
      <path
        data-name="Pfad 806"
        d="M25.906 36.183h-2.213a1.784 1.784 0 0 0-1.784 1.784v9.046a1.784 1.784 0 0 0 1.784 1.784h2.212a1.784 1.784 0 0 0 1.784-1.784v-2.659h32.99a1.784 1.784 0 1 0 0-3.568h-32.9v-2.783a1.784 1.784 0 0 0-1.873-1.82Z"
      />
      <path
        data-name="Pfad 807"
        d="M47.423 1.784A1.784 1.784 0 0 0 45.643 0h-2.288a1.784 1.784 0 0 0-1.784 1.784v2.712H1.837a1.784 1.784 0 1 0 0 3.568h39.7v2.8a1.784 1.784 0 0 0 1.784 1.784h2.212a1.784 1.784 0 0 0 1.784-1.784Z"
      />
    </g>
  </svg>
)
