import { CheckIcon } from "@heroicons/react/outline"
import React, { useEffect, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import { sr, srConfig } from "../../util/sr"
import ContactBanner from "../contact/ContactBanner"

const productPackages = {
  tiers: [
    {
      title: 'Dice-Package-Product-1-Title',
      description: 'Dice-Package-Product-1-Description',
      features: ['Dice-Package-Product-1-Price-Modell', 'Dice-Package-Product-1-Users', 'Dice-Package-Product-1-Management', 'Dice-Package-Product-1-Entities','Dice-Package-Product-included-1', 'Dice-Package-Product-included-2', 'Dice-Package-Product-included-3', 'Dice-Package-Product-1-Training-Support'],
      cta: 'Monthly billing',
    },
    {
      title: 'Dice-Package-Product-2-Title',
      description: 'Dice-Package-Product-2-Description',
      features: ['Dice-Package-Product-2-Price-Modell', 'Dice-Package-Product-2-Users', 'Dice-Package-Product-2-Management', 'Dice-Package-Product-2-Entities','Dice-Package-Product-included-1', 'Dice-Package-Product-included-2', 'Dice-Package-Product-included-3','Dice-Package-Product-2-Training-Support'],
      
      cta: 'Monthly billing',
    },
    {
      title: 'Dice-Package-Product-3-Title',
      description: 'Dice-Package-Product-3-Description',
      features: ['Dice-Package-Product-3-Price-Modell', 'Dice-Package-Product-3-Users', 'Dice-Package-Product-3-Management', 'Dice-Package-Product-3-Entities','Dice-Package-Product-included-1', 'Dice-Package-Product-included-2', 'Dice-Package-Product-included-3','Dice-Package-Product-3-Training-Support'],
      
      cta: 'Monthly billing',
    },
  ],
}

const DicePackages = () => {
  const { t } = useTranslation()
  const headlineRef = useRef(null)
  const packRef = useRef(null)

  useEffect(() => {
    if (sr && headlineRef.current && packRef.current) {
      sr.reveal(headlineRef.current, srConfig())
      sr.reveal(packRef.current, srConfig(400))
    }
  }, [])

  return (
    <div className="base-container pt-28">
      <div ref={headlineRef} className="text-center md:mb-16 load-hidden">
        <h2 className="">
          <Trans>Dice-Package-Headline</Trans>
        </h2>
      </div>
      <div ref={packRef} className='mb-12 load-hidden'>
        <div className="mx-2 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          {productPackages.tiers.map((tier) => (
            <div
              key={tier.title}
              className="relative flex flex-col p-8 bg-white border shadow-sm border-primary-200 rounded-2xl"
            >
              <div className="flex-1">
                <p className="flex items-baseline mt-4 text-gray-900">
                  <span className="text-3xl font-extrabold tracking-tight">{t(tier.title)}</span>
                </p>
                <p className="mt-6 text-gray-500">{t(tier.description)}</p>

                {/* Feature list */}
                <ul role="list" className="mt-6 space-y-6">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex">
                      <CheckIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                      <span className="ml-3 text-gray-500">{t(feature)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ContactBanner />
    </div>
  )
}

export default DicePackages
