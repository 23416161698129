import React, { useEffect, useRef } from "react"

import { Disclosure, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Trans, useTranslation } from "react-i18next"
import { langFileFilter } from "../../util/langUtils"
import { sr, srConfig } from "../../util/sr"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const FAQ = () => {
  const { i18n } = useTranslation()

  const headlineRef = useRef(null)
  const contentRef = useRef(null)

  useEffect(() => {
    if (sr && headlineRef.current && contentRef.current) {
      sr.reveal(headlineRef.current, srConfig(200))
      sr.reveal(contentRef.current, srConfig(400))
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      faq: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/faq/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              date
              question
              answer
            }
            fileAbsolutePath
          }
        }
      }
    }
  `)
  const faqs = langFileFilter(data.faq.edges, i18n.language)

  return (
    <div className="bg-slate-50">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto">
          <div ref={headlineRef} className='load-hidden'>
            <h1 className="text-center">
              <Trans>FAQ</Trans>
            </h1>
            <p className="mt-4 text-center">
              <Trans>FAQ-Subtext</Trans>
            </p>
          </div>
          <dl ref={contentRef} className="mt-6 space-y-2 load-hidden">
            {faqs.map(faq => (
              <Disclosure
                as="div"
                key={faq.node.frontmatter.question}
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-base">
                      <Disclosure.Button className="flex items-start justify-between w-full px-6 py-4 text-left shadow-md text-slate-800 bg-slate-100">
                        <span className="self-center font-sans font-bold">
                          {faq.node.frontmatter.question}
                        </span>
                        <span className="flex items-center ml-6 h-7">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transition-all"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="scale-95 opacity-0"
                      enterTo="scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="scale-100 opacity-100"
                      leaveTo="scale-95 opacity-0"
                    >
                      <Disclosure.Panel as="dd" className="px-6 mt-4">
                        <p className="text-base text-secondary">
                          {faq.node.frontmatter.answer}
                        </p>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default FAQ
