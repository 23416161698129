import React, { useEffect, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import { sr, srConfig } from "../../util/sr"
import CloudIcon from "./CloudIcon"
import EthIcon from "./EthIcon"

const TechItem = ({
  renderIcon,
  text,
}: {
  renderIcon: () => React.ReactNode
  text: string[]
}) => (
  <div className="grid grid-cols-1 md:grid-cols-3">
    <div className="px-24 mb-2 md:px-0 lg:mt-7">{renderIcon()}</div>
    <div className="text-left md:col-span-2">
      {text.map(p => (
        <p className="leading-7">{p}</p>
      ))}
    </div>
  </div>
)

const Technology = () => {
  const { t } = useTranslation()
  const headlineRef = useRef(null)
  const ethRef = useRef(null)
  const cloudRef = useRef(null)

  useEffect(() => {
    if (sr && headlineRef.current && ethRef.current && cloudRef.current) {
      sr.reveal(headlineRef.current, srConfig())
      sr.reveal(ethRef.current, srConfig(400))
      sr.reveal(cloudRef.current, srConfig(600))
    }
  }, [])

  return (
    <div className="mx-auto max-w-7xl py-28">
      <div ref={headlineRef} className="text-center md:mb-20 load-hidden">
        <div className="pre-heading">
          <Trans>Technology-Pre-Headline</Trans>
        </div>
        <h2 className="">
          <Trans>Technology-Headline</Trans>
        </h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-10">
        <div ref={ethRef} className='load-hidden'>
          <TechItem
            text={[
              t("Technology-Ethereum-Paragraph-1"),
              t("Technology-Ethereum-Paragraph-2"),
              t("Technology-Ethereum-Paragraph-3"),
            ]}
            renderIcon={EthIcon}
          />
        </div>
        <div ref={cloudRef} className='load-hidden'>
          <TechItem
            text={[
              t("Technology-Cloud-Paragraph-1"),
              t("Technology-Cloud-Paragraph-2"),
              t("Technology-Cloud-Paragraph-3"),
            ]}
            renderIcon={CloudIcon}
          />
        </div>
      </div>
    </div>
  )
}

export default Technology
