import * as React from "react"

export const MoneyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72.759}
    height={41.503}
    {...props}
  >
    <g data-name="Zeichenfla\u0308che 57" fill="#222b45">
      <path
        data-name="Pfad 815"
        d="M44.872 40.589a1.534 1.534 0 0 0 1.552-1.534v-4.639h4.626a1.552 1.552 0 0 0 1.552-1.552V8.135a1.534 1.534 0 0 0-1.552-1.534H7.725a1.534 1.534 0 0 0-1.552 1.534v4.639H1.55A1.552 1.552 0 0 0 0 14.326v24.729a1.534 1.534 0 0 0 1.55 1.534ZM9.278 9.689h40.179V31.33h-3.086V14.273a1.552 1.552 0 0 0-1.552-1.552H9.278Zm-6.191 6.172h40.179v21.606H3.087Z"
      />
      <path
        data-name="Pfad 816"
        d="M23.176 18.966a7.725 7.725 0 1 0 5.488 2.288 7.725 7.725 0 0 0-5.488-2.288Zm0 12.364a4.639 4.639 0 1 1 4.639-4.639 4.639 4.639 0 0 1-4.639 4.639Z"
      />
      <path
        data-name="Pfad 817"
        d="M65.979 0a1.374 1.374 0 0 0-1.374 1.374l.143 35.5-2.409-2.623a1.249 1.249 0 0 0-1.784 0 1.178 1.178 0 0 0 0 1.784l4.83 5.054a1.267 1.267 0 0 0 1.873 0l5.127-4.746a1.249 1.249 0 0 0 0-1.784 1.267 1.267 0 0 0-1.873 0l-2.927 2.659-.232-35.844A1.374 1.374 0 0 0 65.979.018Z"
      />
    </g>
  </svg>
)
