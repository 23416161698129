import React, { useEffect, useReducer, useRef } from "react"
import { Trans } from "react-i18next"
import { sr, srConfig } from "../../util/sr"
import ShiftedBoxIcon from "../common/ShiftedBoxIcox"

interface IIconWall {
  items: {
    renderIcon: () => React.ReactNode
    renderText: () => React.ReactNode
  }[]
}

const IconWall = ({ items }: IIconWall) => {
  const headlineRef = useRef(null)
  const iconRefs = items.map(() => useRef(null))

  useEffect(() => {
    if (sr && headlineRef.current && !iconRefs.some(ref => !ref.current)) {
      sr.reveal(headlineRef.current, srConfig(200))
      iconRefs.forEach((iRef, i) =>
        sr.reveal(iRef.current, srConfig((i + 4) * 100))
      )
    }
  }, [])

  return (
    <div className="relative left-0 w-full px-4 pt-16 pb-24 bg-slate-50 sm:px-8 lg:px-16">
      <div ref={headlineRef} className='load-hidden'>
        <div className="text-center pre-heading">
          <Trans>IconWall-Preheader</Trans>
        </div>
        <h2 className="mb-24 text-center">
          <Trans>IconWall-Header</Trans>
        </h2>
      </div>
      <div className="grid grid-cols-2 mx-auto text-center max-w-8xl lg:grid-cols-4 gap-y-8 md:gap-y-12 lg:gap-y-20 gap-x-2">
        {items.map(({ renderIcon, renderText }, i) => (
          <div ref={iconRefs[i]} key={i} className="mb-20 lg:mb-0">
            <ShiftedBoxIcon
              className="mx-auto"
              heightClass="h-28"
              widthClass="w-28"
              renderIcon={() => renderIcon()}
            />
            <div className="mt-8">{renderText()}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default IconWall
