import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import SolutionIllustrationMobile from "./SolutionIllustrationMobile"
import { StaticImage } from "gatsby-plugin-image"

interface IStaticSolutionBoxProps {}

const StaticSolutionBox = ({}: IStaticSolutionBoxProps) => {
  return (
    <div className="pt-24 pb-1 mb-24 snap-y snap-always">
      <div className="max-w-xl mx-auto">
        <SolutionIllustrationMobile />
      </div>
      <div className="snap-center">
        <div className="pre-heading">
          <Trans>Solution-Subheader-1</Trans>
        </div>
        <h2 className="mb-8">
          <Trans>Solution-Header-1</Trans>
        </h2>
        <p className="mb-4">
          <Trans>Solution-Paragraph-1</Trans>
        </p>
        <p className="mb-4">
          <Trans>Solution-Paragraph-2</Trans>
        </p>
        <p className="mb-4">
          <Trans>Solution-Paragraph-3</Trans>
        </p>
      </div>

      <div className="hidden grid-cols-1 gap-12 mb-24 md:grid md:grid-cols-3">
        <div className="mt-24 align-middle snap-center md:col-span-2">
          <div className="pre-heading">
            <Trans>Solution-Subheader-2</Trans>
          </div>
          <h2 className="mb-8">
            <Trans>Solution-Header-2</Trans>
          </h2>
          <p className="mb-4">
            <Trans>Solution-2-Paragraph-1</Trans>
          </p>
          <p className="mb-4">
            <Trans>Solution-2-Paragraph-2</Trans>
          </p>
        </div>
        <div className="max-w-xs m-auto snap-center">
          <StaticImage
            src="../../images/DICE-ID.svg"
            alt="Dice. ID Illustration"
            className="mt-8 -mb-32 md:-mb-40"
            placeholder="blurred"
            quality={90}
            backgroundColor="transparent"
          />
        </div>
      </div>
      <div className="block md:hidden">
        <div className="max-w-xs m-auto snap-center">
          <StaticImage
            src="../../images/DICE-ID.svg"
            alt="Dice. ID Illustration"
            className="mt-8 -mb-24 md:-mb-40"
            placeholder="blurred"
            quality={90}
            backgroundColor="transparent"
          />
        </div>
        <div className="mt-24 snap-center">
          <div className="pre-heading">
            <Trans>Solution-Subheader-2</Trans>
          </div>
          <h2 className="mb-8">
            <Trans>Solution-Header-2</Trans>
          </h2>
          <p className="mb-4">
            <Trans>Solution-2-Paragraph-1</Trans>
          </p>
          <p className="mb-4">
            <Trans>Solution-2-Paragraph-2</Trans>
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-12 mb-24 md:grid md:grid-cols-3">
        <div className="max-w-xs m-auto snap-center">
          <StaticImage
            src="../../images/DICE-Controls.svg"
            alt="Dice. ID Illustration"
            className="px-12 mt-8 -mb-24 md:-mb-40"
            placeholder="blurred"
            quality={90}
            backgroundColor="transparent"
          />
        </div>
        <div className="mt-24 snap-center md:col-span-2">
          <div className="pre-heading">
            <Trans>Solution-Subheader-3</Trans>
          </div>
          <h2 className="mb-8">
            <Trans>Solution-Header-3</Trans>
          </h2>
          <p className="mb-4">
            <Trans>Solution-3-Paragraph-1</Trans>
          </p>
          <p className="mb-4">
            <Trans>Solution-3-Paragraph-2</Trans>
          </p>
        </div>
      </div>
    </div>
  )
}

export default StaticSolutionBox
