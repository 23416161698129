import * as React from "react"

export const MagnifyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51.505}
    height={53.13}
    {...props}
  >
    <path
      data-name="Zeichenfla\u0308che 58"
      d="M21.856 41.444a19.108 19.108 0 1 1 18.805-19.108 19.108 19.108 0 0 1-18.805 19.108m13.328-1.32 12.025 12.257a2.48 2.48 0 0 0 1.784.749 2.444 2.444 0 0 0 1.784-.749 2.551 2.551 0 0 0 0-3.568L38.859 36.659l.678-.928a22.819 22.819 0 0 0-2.159-29.242A21.41 21.41 0 0 0 25.531.369a21.018 21.018 0 0 0-19.108 6.1l-.482.5A22.284 22.284 0 0 0 .214 18.607 15.95 15.95 0 0 0 0 20.391v1.784a23.961 23.961 0 0 0 1.285 7.476 15.352 15.352 0 0 0 .749 1.784c.268.517.5 1.178.785 1.784a21.089 21.089 0 0 0 3.568 4.746 21.91 21.91 0 0 0 27.963 2.819Z"
      fill="#222b45"
    />
  </svg>
)
