import * as React from "react"

export const AtomIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Zeichenfla\u0308che 53"
    xmlns="http://www.w3.org/2000/svg"
    width={66.842}
    height={66.889}
    {...props}
  >
    <path
      data-name="Pfad 798"
      d="m31.283 47.386 4.575 4.65a1.4 1.4 0 0 1 0 1.971 1.381 1.381 0 0 1-1.955 0l-5.57-5.57a1.365 1.365 0 0 1 0-1.971l5.57-5.57a1.381 1.381 0 0 1 1.955 0 1.4 1.4 0 0 1 0 1.971Z"
      fill="#222b45"
    />
    <path
      data-name="Pfad 799"
      d="m47.355 35.671 4.655-4.655a1.4 1.4 0 0 1 1.971 1.971l-5.637 5.632a1.4 1.4 0 0 1-1.971 0l-5.57-5.57a1.4 1.4 0 0 1 1.971-1.971Z"
      fill="#222b45"
    />
    <path
      data-name="Pfad 800"
      d="M20.915 44.872c-6.517-1.241-11.87-3.4-15.1-6.206a7.153 7.153 0 0 1-3.026-5.244c0-2.669 2.126-5.027 5.493-7.029 5.678-3.321 14.837-5.383 25.139-5.383s19.472 2.14 25.139 5.506c3.367 2 5.508 4.36 5.508 7.029s-2.141 5.027-5.508 7.013c-5.663 3.289-14.864 5.431-25.136 5.431h-4.177a1.4 1.4 0 0 0 0 2.793h4.174c10.861 0 20.558-2.343 26.563-5.9 4.391-2.622 6.858-5.943 6.858-9.418s-2.467-6.811-6.858-9.356c-6-3.553-15.655-5.912-26.563-5.912s-20.561 2.3-26.547 5.914C2.467 26.656 0 29.976 0 33.421a9.853 9.853 0 0 0 4.019 7.448c3.507 2.979 9.309 5.4 16.369 6.734a1.4 1.4 0 1 0 .528-2.731Z"
      fill="#222b45"
    />
    <path
      data-name="Pfad 801"
      d="M44.825 45.973c-1.228 6.517-3.382 11.867-6.113 15.097a7.153 7.153 0 0 1-5.291 3.1c-2.669 0-5.027-2.126-7.013-5.493-3.367-5.675-5.511-14.829-5.511-25.132s2.141-19.472 5.508-25.136c1.992-3.369 4.347-5.508 7.016-5.508S38.448 5.04 40.45 8.41c3.367 5.663 5.508 14.818 5.508 25.136v4.174a1.4 1.4 0 1 0 2.777 0v-4.3c0-10.861-2.343-20.574-5.9-26.563C40.233 2.451 36.912 0 33.421 0s-6.8 2.5-9.418 6.9c-3.584 5.989-5.9 15.655-5.9 26.517s2.312 20.6 5.9 26.594c2.622 4.438 5.943 6.873 9.418 6.873a9.915 9.915 0 0 0 7.417-3.988c2.964-3.507 5.384-9.309 6.718-16.369a1.365 1.365 0 0 0-1.1-1.552 1.4 1.4 0 0 0-1.629.993Z"
      fill="#222b45"
    />
  </svg>
)
