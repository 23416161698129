import * as React from "react"

export const CloudStorage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={73.835}
    height={58.414}
    {...props}
  >
    <g data-name="Zeichenfla\u0308che 61" fill="#222b45">
      <path
        data-name="Pfad 818"
        d="M10.277 53.489h13.506a1.124 1.124 0 0 0 0-2.23H11.401V38.41l1.427 1.427a1.124 1.124 0 0 0 .8.339 1.053 1.053 0 0 0 .855-.266 1.088 1.088 0 0 0 0-1.57l-3.331-3.33a1.071 1.071 0 0 0-.375-.25 1.178 1.178 0 0 0-.856 0 1.534 1.534 0 0 0-.357.232L6.156 38.27a1.11 1.11 0 1 0 1.57 1.57l1.445-1.43v13.991a1.124 1.124 0 0 0 1.106 1.088Z"
      />
      <path
        data-name="Pfad 819"
        d="M50.171 7.155h12.364v12.846l-1.427-1.427a1.124 1.124 0 1 0-1.588 1.57l3.354 3.336a1.178 1.178 0 0 0 .357.25 1.231 1.231 0 0 0 .428.089 1.142 1.142 0 0 0 .428-.089 1.07 1.07 0 0 0 .375-.25l3.336-3.336a1.136 1.136 0 0 0-1.57-1.641l-1.427 1.427V6.015a1.124 1.124 0 0 0-1.124-1.124H50.171a1.124 1.124 0 1 0 0 2.23Z"
      />
      <path
        data-name="Pfad 820"
        d="M64.605 37.914a12.7 12.7 0 0 0 .125-1.645 11.49 11.49 0 0 0-20.232-7.44 7.226 7.226 0 0 0-10.01 6.73 7.137 7.137 0 0 0 .5 2.569 11.1 11.1 0 0 0 6.267 20.286h22.351a10.277 10.277 0 0 0 1-20.5Zm-1 18.252h-22.35a8.921 8.921 0 0 1-4.246-16.628 1.2 1.2 0 0 0 .535-.678 1.035 1.035 0 0 0-.107-.856 4.889 4.889 0 0 1-.66-2.444 5.031 5.031 0 0 1 5.031-5.031 5.1 5.1 0 0 1 2.5.678 1.088 1.088 0 0 0 1.463-.3 9.242 9.242 0 0 1 16.753 5.352 8.76 8.76 0 0 1-.339 2.426 1.124 1.124 0 0 0 .214 1 1.088 1.088 0 0 0 .91.428h.339a8.029 8.029 0 1 1 0 16.057Z"
      />
      <path
        data-name="Pfad 821"
        d="M2.73 23.961h29.974a2.712 2.712 0 0 0 2.73-2.712v-7.654a2.641 2.641 0 0 0-.535-1.606 2.712 2.712 0 0 0 .535-1.606V2.73A2.73 2.73 0 0 0 32.704 0H2.73A2.73 2.73 0 0 0 0 2.73v7.654a2.712 2.712 0 0 0 .535 1.606A2.641 2.641 0 0 0 0 13.596v7.654a2.712 2.712 0 0 0 2.73 2.712ZM2.23 2.73a.5.5 0 0 1 .5-.482h29.974a.5.5 0 0 1 .5.482v7.654a.5.5 0 0 1-.5.482H2.73a.5.5 0 0 1-.5-.482Zm0 10.866a.5.5 0 0 1 .5-.446h29.974a.5.5 0 0 1 .5.482v7.654a.5.5 0 0 1-.5.482H2.73a.5.5 0 0 1-.5-.482Z"
      />
      <path
        data-name="Pfad 822"
        d="M28.582 7.672h.607a1.124 1.124 0 1 0 0-2.23h-.607a1.124 1.124 0 1 0 0 2.23Z"
      />
      <path
        data-name="Pfad 823"
        d="M23.194 7.672h.607a1.124 1.124 0 1 0 0-2.23h-.607a1.124 1.124 0 0 0 0 2.23Z"
      />
      <path
        data-name="Pfad 824"
        d="M5.37 7.672h12.489a1.124 1.124 0 0 0 0-2.23H5.37a1.124 1.124 0 1 0 0 2.23Z"
      />
      <path
        data-name="Pfad 825"
        d="M28.582 18.502h.607a1.124 1.124 0 1 0 0-2.23h-.607a1.124 1.124 0 1 0 0 2.23Z"
      />
      <path
        data-name="Pfad 826"
        d="M23.194 18.502h.607a1.124 1.124 0 1 0 0-2.23h-.607a1.124 1.124 0 0 0 0 2.23Z"
      />
      <path
        data-name="Pfad 827"
        d="M5.37 18.502h12.489a1.124 1.124 0 0 0 0-2.23H5.37a1.124 1.124 0 1 0 0 2.23Z"
      />
    </g>
  </svg>
)
