import React, { useEffect, useRef, useState } from "react"
import { Trans } from "react-i18next"
import { sr, srConfig } from "../../util/sr"
import SolutionTowerSvg from "./SolutionTowerSvg"

interface IDiceTechnologyProps {}

const DiceTechnology = ({}: IDiceTechnologyProps) => {
  const imageRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    if (sr && imageRef.current && textRef.current) {
      sr.reveal(imageRef.current, srConfig(200))
      sr.reveal(textRef.current, srConfig(300))
    }
  }, [])

  return (
    <div className="grid grid-cols-1 gap-12 mb-24 base-container md:grid-cols-3">
      <div ref={imageRef} className='load-hidden'>
        <SolutionTowerSvg className="order-2 max-w-xs m-auto md:max-w-md md:order-0 lg:px-12" />
      </div>
      <div ref={textRef} className="order-1 md:col-span-2 load-hidden">
        <div className="pre-heading">
          <Trans>Dice-Technology-Header-Part-1</Trans>
        </div>
        <h1 className="mb-8">
          <Trans>Dice-Technology-Header-Part-2</Trans>
        </h1>
        <p className="mt-4">
          <Trans>Dice-Technology-Paragraph-1</Trans>
        </p>
        <p className="mt-4">
          <Trans>Dice-Technology-Paragraph-2</Trans>
        </p>
      </div>
    </div>
  )
}

export default DiceTechnology
