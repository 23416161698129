import React, { useRef, useState } from "react"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import SolutionIllustration from "./SolutionIllustration"
import { Trans } from "react-i18next"
import { CloudStorage } from "./IconWallIcons"

interface IAnimatedSolutionBoxProps {}

const AnimatedSolutionBox = ({}: IAnimatedSolutionBoxProps) => {
  const controllerRef = useRef()
  // const [pos, setPos] = useState(0)
  // const sceneRef = useRef()
  const xpos = "40vw"
  const defaultDuration = 400
  const switchDuration = 50
  const totalDuration = defaultDuration*2.5+4*switchDuration
  const switch1 = (defaultDuration/2 + switchDuration)/totalDuration
  const switch2 = (defaultDuration*1.5 + switchDuration*3)/totalDuration
  return (
    <>
      {/* <div>
        <button
          type="submit"
          className="fixed z-40 w-20 h-20 text-xl rounded-md hover:bg-slate-300 top-20"
          onClick={() => {
            console.log(controllerRef!.current.state.controller)
            controllerRef!.current!.state.controller.scrollTo(
              // defaultDuration * pos
              controllerRef?.current?.state?.controller?.scrollPos() +
                defaultDuration / 2
            )
            setPos(pos + 1)
            // debugger
          }}
        >
          Jump {controllerRef?.current?.state?.controller?.scrollPos()}
        </button>
      </div> */}
      <Controller vertical={true}>
        <div>
          <Scene triggerHook="onLeave" duration={totalDuration} pin indicators={false}>
            {progress => (
              <div className="relative top-0 grid w-full min-h-screen gap-8 overflow-hidden snap-y snap-always md:grid-cols-5 md:px-12 place-items-center">
                <Timeline totalProgress={progress}>
                  <Tween
                  // duration={1}
                  // from={{ rotation: 10 }}
                  // to={{ rotation: 90 }}
                  >
                    <SolutionIllustration
                      className="md:col-span-3"
                      isIdFocused={progress < switch2}
                      isControlsFocused={progress < switch1 || progress > switch2}
                    />
                    {/* <div className={"absolute top-20 text-xl"}>
                      {Math.floor(progress * 100)}%
                    </div> */}
                  </Tween>
                  <Timeline
                    target={
                      <div className="absolute col-span-2 col-start-4 snap-center">
                        <div className="pre-heading">
                          <Trans>Solution-Subheader-1</Trans>
                        </div>
                        <div className="mb-8 text-2xl font-extrabold md:text-4xl text-slate-800">
                          <Trans>Solution-Header-1</Trans>
                        </div>
                        <p className="mb-4">
                          <Trans>Solution-Paragraph-1</Trans>
                        </p>
                        <p className="mb-4">
                          <Trans>Solution-Paragraph-2</Trans>
                        </p>
                        <p className="mb-4">
                          <Trans>Solution-Paragraph-3</Trans>
                        </p>
                      </div>
                    }
                  >
                    {/* <Tween duration={1} from={{ opacity: 0.5 }} to={{ opacity: 1 }} /> */}
                    {/* <Tween duration={1} from={{ display: "block" }} to={{ display: "none" }} /> */}
                    <Tween duration ={defaultDuration/2} />
                    <Tween duration={switchDuration} to={{ opacity:0 }} />
                  </Timeline>
                  <Timeline
                    target={
                      <div className="absolute col-span-2 col-start-4 snap-center">
                        <div className="pre-heading">
                          <Trans>Solution-Subheader-2</Trans>
                        </div>
                        <h1 className="mb-8">
                          <Trans>Solution-Header-2</Trans>
                        </h1>
                        <p className="mb-4">
                          <Trans>Solution-2-Paragraph-1</Trans>
                        </p>
                        <p className="mb-4">
                          <Trans>Solution-2-Paragraph-2</Trans>
                        </p>
                      </div>
                    }
                  >
                    {/* <Tween from={{ opacity: 0.5 }} to={{ opacity: 1 }} /> */}
                    <Tween
                      duration={switchDuration}
                      from={{ opacity: 0 }}
                      to={{ opacity: 1 }}
                    />
                    <Tween duration={defaultDuration} />

                    <Tween duration={switchDuration} to={{ opacity: 0 }} />
                  </Timeline>
                  <Timeline
                    target={
                      <div className="absolute col-span-2 col-start-4 snap-center">
                        <div className="pre-heading">
                          <Trans>Solution-Subheader-3</Trans>
                        </div>
                        <h1 className="mb-8">
                          <Trans>Solution-Header-3</Trans>
                        </h1>
                        <p className="mb-4">
                          <Trans>Solution-3-Paragraph-1</Trans>
                        </p>
                        <p className="mb-4">
                          <Trans>Solution-3-Paragraph-2</Trans>
                        </p>
                      </div>
                    }
                  >
                    {/* <Tween duration={1} from={{ opacity: 0.5 }} to={{ opacity: 1 }} /> */}
                    <Tween
                      duration={switchDuration}
                      from={{ opacity:0 }}
                      to={{ opacity:1 }}
                    />
                    <Tween duration={defaultDuration} />
                    
                    {/* <Tween duration={defaultDuration / 2} to={{ y: "-200%" }} /> */}
                  </Timeline>
                </Timeline>
              </div>
            )}
          </Scene>
        </div>
      </Controller>
    </>
  )
}

export default AnimatedSolutionBox
