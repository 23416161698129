import * as React from "react"

export const ChartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Zeichenfla\u0308che 56"
    xmlns="http://www.w3.org/2000/svg"
    width={45.857}
    height={55.132}
    {...props}
  >
    <path
      data-name="Pfad 810"
      d="M8.281 33.576H1.322a1.249 1.249 0 0 0-1.32 1.3v18.949a1.249 1.249 0 0 0 1.32 1.3h6.959a1.249 1.249 0 0 0 1.32-1.3V34.878a1.32 1.32 0 0 0-1.32-1.3Zm-1.32 18.912H2.624V36.199H6.96Z"
      fill="#222b45"
    />
    <path
      data-name="Pfad 811"
      d="M20.359 24.12h-6.962a1.231 1.231 0 0 0-1.3 1.3v28.4a1.231 1.231 0 0 0 1.3 1.3h6.958a1.231 1.231 0 0 0 1.32-1.3v-28.4a1.32 1.32 0 0 0-1.32-1.3Zm-1.3 28.368h-4.338V26.743h4.336Z"
      fill="#222b45"
    />
    <path
      data-name="Pfad 812"
      d="M32.455 28.581h-6.958a1.231 1.231 0 0 0-1.32 1.32v23.765a1.231 1.231 0 0 0 1.32 1.32h6.958a1.249 1.249 0 0 0 1.32-1.32V29.901a1.427 1.427 0 0 0-1.32-1.32Zm-1.32 23.907h-4.336V31.203h4.336Z"
      fill="#222b45"
    />
    <path
      data-name="Pfad 813"
      d="M44.534 21.71h-6.958a1.249 1.249 0 0 0-1.32 1.3v30.816a1.249 1.249 0 0 0 1.32 1.3h6.958a1.231 1.231 0 0 0 1.32-1.3v-30.9a1.32 1.32 0 0 0-1.32-1.216Zm-1.3 30.866h-4.338V24.244h4.336Z"
      fill="#222b45"
    />
    <path
      data-name="Pfad 814"
      d="m16.951 11.113 11.169 8.279a1.427 1.427 0 0 0 1.784-.268L41.073 4.851l.268 3.568a1.231 1.231 0 0 0 1.445 1.178 1.2 1.2 0 0 0 1.178-1.445l-.66-6.958A1.249 1.249 0 0 0 41.859.016l-6.958.517a1.32 1.32 0 0 0 .268 2.623l3.925-.25-10.457 13.453-11.03-8.136a1.249 1.249 0 0 0-1.784.125L3.607 20.962a1.285 1.285 0 0 0 0 1.784 1.285 1.285 0 0 0 1.784 0Z"
      fill="#222b45"
    />
  </svg>
)
