import * as React from "react"

export const CogIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Zeichenfla\u0308che 55"
    xmlns="http://www.w3.org/2000/svg"
    width={55.605}
    height={54.364}
    {...props}
  >
    <path
      data-name="Pfad 808"
      d="M55.504 21.41a3.087 3.087 0 0 0-.285-2.284l-6.8-11.793a2.944 2.944 0 0 0-3.979-1.088l-5.061 2.89a19.876 19.876 0 0 0-2.105-1.16V2.89A2.908 2.908 0 0 0 34.379 0h-12.81a2.908 2.908 0 0 0-2.89 2.89v5a13.916 13.916 0 0 0-2.105 1.16l-4.978-2.89a2.926 2.926 0 0 0-3.979 1.017l-7.03 11.7A3.069 3.069 0 0 0 .23 21.12a3.247 3.247 0 0 0 1.3 1.784l4.853 2.926a9.206 9.206 0 0 0 0 1.374v1.16l-5 2.819a3.568 3.568 0 0 0-1.3 1.784 3.122 3.122 0 0 0 .285 2.212l6.8 11.793a2.873 2.873 0 0 0 3.911 1.093l5.21-2.89a18.914 18.914 0 0 0 2.319 1.3v4.978a2.908 2.908 0 0 0 2.89 2.908h12.881a2.908 2.908 0 0 0 2.89-2.908V46.37a9.9 9.9 0 0 0 1.891-1.07l4.906 2.89a2.926 2.926 0 0 0 3.979-1.017l6.958-11.722a2.89 2.89 0 0 0 .285-2.177 3.3 3.3 0 0 0-1.3-1.784l-4.782-2.908a9.458 9.458 0 0 0 0-1.374 6.333 6.333 0 0 0 0-1.16l4.924-2.819a2.765 2.765 0 0 0 1.374-1.82Zm-2.73-.714-5.709 3.319a1.427 1.427 0 0 0-.732 1.374 16.056 16.056 0 0 1 0 1.784 9.931 9.931 0 0 1-.143 2.016 1.445 1.445 0 0 0 .732 1.445l5.638 3.408-6.923 11.847-5.758-3.569a1.427 1.427 0 0 0-1.588 0 15.665 15.665 0 0 1-3.033 1.677 1.409 1.409 0 0 0-.874 1.3v6.066H21.497v-5.778a1.463 1.463 0 0 0-.946-1.374 14.113 14.113 0 0 1-3.319-1.784 1.392 1.392 0 0 0-1.588 0l-5.94 3.193-6.8-11.793 5.709-3.336a1.409 1.409 0 0 0 .732-1.374 15.826 15.826 0 0 1 0-1.784 10.828 10.828 0 0 1 .143-2.034 1.463 1.463 0 0 0-.732-1.445l-5.638-3.39L10.13 8.742l5.729 3.248a1.427 1.427 0 0 0 1.588 0 11.722 11.722 0 0 1 3.1-1.784 1.409 1.409 0 0 0 .946-1.374V2.82h12.886v6.084a1.427 1.427 0 0 0 .874 1.3 15.272 15.272 0 0 1 3.247 1.784 1.267 1.267 0 0 0 1.517 0l5.87-3.319 6.887 12.025 1.374.357Z"
      fill="#222b45"
    />
    <path
      data-name="Pfad 809"
      d="M27.796 16.129a11.08 11.08 0 1 0 11.061 11.08 11.08 11.08 0 0 0-11.061-11.08Zm0 19.251a8.171 8.171 0 1 1 8.171-8.171 8.171 8.171 0 0 1-8.171 8.171Z"
      fill="#222b45"
    />
  </svg>
)
