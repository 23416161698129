import React, { useEffect, useState } from "react"

import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Technology from "../components/solution/Technology"
import IconWall from "../components/solution/IconWall"
import { Trans } from "react-i18next"
import {
  AtomIcon,
  ChartIcon,
  CloudStorage,
  CogIcon,
  MagnifyIcon,
  MoneyIcon,
  SettingsIcon,
  ShieldIcon,
} from "../components/solution/IconWallIcons"
import Faq from "../components/solution/Faq"
import AnimatedSolutionBox from "../components/solution/AnimatedSolutionBox"
import DiceTechnology from "../components/solution/DiceTechnology"
import ArticleBox from "../components/solution/ArticleBox"
import StaticSolutionBox from "../components/solution/StaticSolutionBox"
import DicePackages from "../components/solution/DicePackages"

const SolutionPage = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  )

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        windowWidth !== (typeof window !== "undefined" ? window.innerWidth : 0)
      )
        setWindowWidth(typeof window !== "undefined" ? window.innerWidth : 0)
    }, 500)

    return () => clearInterval(timer)
  }, [])

  return (
    <>
      <SEO title={t("Solution")} isHome={false} />
      <BaseLayout fixedHeader>
        {windowWidth >= 1024 ? (
          <div className="mx-auto max-w-8xl">
            <AnimatedSolutionBox />
          </div>
        ) : (
          <div className="lg:hidden base-container">
            <StaticSolutionBox />
          </div>)}
        <DiceTechnology />
        <IconWall
          items={[
            {
              renderIcon: () => <AtomIcon />,
              renderText: () => <Trans>Icon-1-Description</Trans>,
            },
            {
              renderIcon: () => <SettingsIcon />,
              renderText: () => <Trans>Icon-2-Description</Trans>,
            },
            {
              renderIcon: () => <CogIcon />,
              renderText: () => <Trans>Icon-3-Description</Trans>,
            },
            {
              renderIcon: () => <ChartIcon />,
              renderText: () => <Trans>Icon-4-Description</Trans>,
            },
            {
              renderIcon: () => <MoneyIcon />,
              renderText: () => <Trans>Icon-5-Description</Trans>,
            },
            {
              renderIcon: () => <MagnifyIcon />,
              renderText: () => <Trans>Icon-6-Description</Trans>,
            },
            {
              renderIcon: () => <ShieldIcon />,
              renderText: () => <Trans>Icon-7-Description</Trans>,
            },
            {
              renderIcon: () => <CloudStorage />,
              renderText: () => <Trans>Icon-8-Description</Trans>,
            },
          ]}
        />
        <div className="px-4 base-container">
          <Technology />
        </div>
        <div className="px-4 pb-20 bg-slate-50">
          <ArticleBox
            prefix={["usecase-"]}
            headline={t("Solution-Articles-Headline")}
            description={t("Solution-Articles-Description")}
            archiveButtonName={t("Solution-Articles-Button")}
            archiveLink={"/use-cases"}
            noDate={true}
            count={3}
          />
        </div>
        <DicePackages />
        <Faq />
      </BaseLayout>
    </>
  )
}

export default SolutionPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
